const INITIAL_STATE = {
  myModules: null,
  engList: {},
  checkOTPError: '',
  otp: {},
  enableEngagement: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "Navigation/NAVIGATE":
      return state;
    case "RESET_APP":
      return { ...INITIAL_STATE };
    case "UPDATE_USERID":
      return { ...state, userId: action.data };
    case "UPDATE_USER_ACCOUNT":
      return { ...state, userAccount: action.data };
    case "UPDATE_MODULES":
      return {
        ...state, myModules: {
          ...state.myModules,
          [action.data.id]: action.data
        },
        engList: ''
      };
    case "UPDATE_UNITS":
      return { ...state, units: { ...state.units, [action.data.id]: action.data.detail } };
    case "CURRENT_MODULE":
      return { ...state, units: { ...state.units, [action.data.id]: action.data.detail } };
    case "TAB_INDEX_CHANGE":
      return { ...state, tabIndex: action.value }
    case "UPDATE_TRACKER":
      return { ...state }
    case "CHECKOTP_SUCCEDED":
      return { ...state, engList: action.payload.engagement, otp: action.payload.otp, checkOTPError: '', enableEngagement: [] }
    case "RESET_ENABLE_ENGAMENT":
      return { ...state, enableEngagement: [] }
    case "CHECKOTP_FAILURE":
      return { ...state, checkOTPError: 'OTP is wrong' }
    case "ENABLE_ENGAGEMENT":
      return { ...state, enableEngagement: [...state.enableEngagement, action.payload] }
    case "DISABLE_ENGAGEMENT": {
      console.log(action)
      console.log(state.enableEngagement)
      return { ...state, enableEngagement: state.enableEngagement.filter(item => item !== action.payload) }
    }

    
    default:
      return state;
  }
};
