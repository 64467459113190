/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { Launch } from "@material-ui/icons";
import pdfLinkIcon from "./assets/Capture.JPG";
import { getHeaders } from "../../containers/app/redux_actions";

//import Skeleton from "react-loading-skeleton";

export default (props) => {
  const [imgUrl, setImgUrl] = useState(null);
  const [linkTitle, setLinkTitle] = useState(null);
  const [source, setSource] = useState(null);
  const [desc, setDesc] = useState(null);
  const [dim, setDimension] = useState(null);
  useEffect(() => {
    // getHeaders(props.url).then(res => {
    //   console.log(res)
    // }).catch(err => console.log(err))
    connect({ target: "url-meta", body: { url: props.url } }).then((a) => {
      let d = a.metadata || {};
      console.log(a);
      if (props.title || props.icon) {
        setImgUrl(props.icon || pdfLinkIcon);
        setLinkTitle(props.title);
        setDesc(props.description);
      } else {
        setImgUrl(d["og:image"] || d.image);
        setLinkTitle(d["og:title"] || d.title);
        setDesc(d["og:description"] || d.description);
      }
      setSource(d["og:site_name"] || d.source);
      setDimension({
        w: parseInt(d["og:image:width"]) || null,
        h: parseInt(d["og:image:height"]) || null,
      });
    });
  }, []);

  let imgRatio = 0.5225,
    w = 110,
    h = 110 * 0.5225;
  if (dim && dim.w && dim.h) {
    imgRatio = dim.h / dim.w;
    h = w * imgRatio;
  }
  return (
    <div style={{ padding: "0px 15px" }}>
      <div
        style={{
          borderTop: "1px solid #b9b9b9",
          display: "flex",
          justifyContent: "space-between",
          padding: "15px 0px",
        }}
      >
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: 110,
              height: 77,
              backgroundColor: "#b7b7b7",
              borderRadius: "3px",
            }}
          >
            {imgUrl && (
              <img
                src={imgUrl}
                alt="link-image"
                style={{
                  width: "100%",
                  maxWidth: "100%",
                  height: "100%",
                  borderRadius: 3,
                }}
                // width={w}
                // height={h}
              />
            )}
          </div>
          <div style={{ width: `calc(100% - ${w}px)`, padding: "0px 10px" }}>
            <div
              style={{ font: "normal normal bold 12px/20px Merriweather Sans" }}
            >
              {linkTitle}
            </div>
            {/* <div style={{ font: "normal normal 300 14px/20px Merriweather Sans" }}>{desc}</div> */}
            <div
              style={{
                font: "normal normal normal 10px/20px Merriweather Sans",
              }}
            >
              {source}
            </div>
          </div>
        </div>
        <a href={props.url} target="_blank">
          {" "}
          <Launch />{" "}
        </a>
      </div>
    </div>
  );
};

function connect({ body, target }) {
  let requestOptions = {
    method: "POST",
    body: JSON.stringify({ ...body }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `https://us-central1-coaching-platform-illumine.cloudfunctions.net/serverResponse/${target}`,
    requestOptions
  )
    .catch((err) => {
      console.log(err);
    })
    .then((response) => response.json());
}
