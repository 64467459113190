import Config from "../../config"
import store from "../../Redux_Store";

const config = {
  apiurl: Config.API_URL,
  // apiurl: "https://uatapi.pedgog.in/v1/",
  // studenturl: "http://localhost:5000/v1/student/",
  studenturl: Config.API_URL + "student/",
}
// console.log(config.apiurl)

export const loginAttempt = ({
  email,
  password,
  successCallBack,
  failureCallback,
}) => {
  return (dispatch) => {
    dispatch({ type: "LOGIN_ATTEMPT", payload: true });
    if (password === "" && email !== "") {
      let errMsg = {};
      errMsg.message = "Please enter a valid password";
      loginAttempFail(dispatch, errMsg, failureCallback);
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({ email, password });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(config.apiurl + "auth/login", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          if (result.code)
            loginAttempFail(dispatch, result, failureCallback)
          var student = false;
          result.user.role.forEach((item, index) => {
            if (item.rolename == "Student")
              student = true
          })
          if (!student) {
            loginAttempFail(dispatch, { message: "This is Student Platform. Only Students are allowed to login" }, failureCallback)
          }
          else {
            getStudentByEmail(dispatch, result.tokens.access.token, result.user.email)
            loginAttemptSuccess(dispatch, result, successCallBack)
          }
        })
        .catch(error => console.log('error', error));
    }
  };
};

export const resetPassword = email => {
  return (dispatch) => {
    if (email) {
      dispatch({ type: "PASSWORD_RESET_IN_PROGRESS", payload: true });
      return fetch(config.apiurl + "auth/forgot-password", {
        "method": "POST",
        "headers": {
          "content-type": "application/json",
          "accept": "application/json"
        },
        "body": JSON.stringify({
          email
        })
      })
        //.then(res => res.json())
        .then(resp => {
          return dispatch({ type: "RESET_PASSWORD_LINK_SENT", payload: true });
        })
        .catch(function (error) {
          let err = getErrorMsg(error);
          setErrorMsgInForgotPassword(dispatch, err.message);
        });
    } else {
      setErrorMsgInForgotPassword(dispatch, "Please enter a valid email id");
    }
  }
};

export const resetPasswordUpdate = data => {
  return (dispatch) => {
    if (data) {
      dispatch({ type: "PASSWORD_UPDATE", payload: true });
      return fetch(config.apiurl + "auth/reset-password", {
        "method": "POST",
        "headers": {
          "content-type": "application/json",
          "accept": "application/json"
        },
        "body": JSON.stringify({
          password: data.password,
          token: data.token,
        })
      })
        .then(res => res.json())
        .then(resp => {
          return dispatch({ type: "PASSWORD_UPDATE_SUCCESS", payload: resp });
        })
        .catch(function (error) {
          let err = getErrorMsg(error);
          setErrorMsgInForgotPassword(dispatch, err.message);
        });
    } else {
      setErrorMsgInForgotPassword(dispatch, "Please enter a valid pasword");
    }
  }
};

const registerAttemptSuccess = (dispatch, user, successCallBack, flag) => {
  dispatch({ type: "REGISTER_ATTEMPT_SUCCESS", payload: user, flag });
  successCallBack(user);
};

export const registerAttempt = ({ email, password, accesscode, fullname, collegeName, organizationid, course, parentid, semester, successCallBack, failureCallback }) => {
  return (dispatch) => {
    dispatch({ type: "REGISTER_ATTEMPT", payload: true });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("fullname", fullname);
    urlencoded.append("email", email);
    urlencoded.append("password", password);
    urlencoded.append("accesscode", accesscode);
    urlencoded.append("collegeName", collegeName);
    urlencoded.append("course", course);
    urlencoded.append("parentid", parentid);
    urlencoded.append("semester", semester);
    urlencoded.append("organizationid", organizationid);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    fetch(config.studenturl + "student/", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.code) {
          registerAttemptFail(dispatch, result, failureCallback);
        }
        else {
          registerAttemptSuccess(dispatch, result, successCallBack)
        }
      })
      .catch(error => console.log('error', error));
  };
};

const getStudentByEmail = (dispatch, token, email) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  fetch(config.studenturl + "student/getStudentByEmailId/" + email, requestOptions)
    .then(response => response.json())
    .then(result => {
      dispatch({ type: "UPDATE_USER_ID", payload: result["id"] })
    })
    .catch(error => console.log('error', error));
}

const registerAttemptFail = (dispatch, err, failureCallback, pos) => {
  dispatch({ type: "REGISTER_ATTEMPT_FAILED", payload: err.message });
  failureCallback();
};

export const postPurchaseOrder = (user_token, productid, _id, response) => {
  fetch(config.studenturl + 'purchaseorder', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "bearer " + user_token,
      "accept": "application/json"
    },
    body: JSON.stringify({
      productCode: productid,
      studentId: _id,
      RazorpayTransactionId: response.razorpay_payment_id,
      RazorpayResponse: JSON.stringify(response),
      status: 'paid',
    })
  })
    .then(response => response.json())
    .then(data2 => {
      console.log('rp in data2');
      console.log(data2);
      window.location.replace("/app");
    }
    )
}
export const getProductDetail = (token, product_id) => {
  return (dispatch) => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "bearer " + token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(config.studenturl + "product/" + product_id, requestOptions)
      .then(response => response.json())
      .then(result => { console.log(result); dispatch({ type: "GET_PRODUCT_INFO", payload: result[0] }) })
      .catch(error => console.log('error', error));
  };
};

export const getUserToken = () => {
  return (dispatch) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "email": "su@ggmail.com",
      "password": "acs12345"
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(config.apiurl + "auth/login", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        dispatch({
          type: "UPDATE_TOKEN",
          payload: {
            token: result["tokens"]["access"]["token"],
            expiry: result["tokens"]["access"]["expires"]
          }
        })
      })
      .catch(error => console.log('error', error));
  }
}

export const setErrMsg = (el) => {
  return (dispatch) => {
    dispatch({ type: "SET_LOGIN_ERROR_MSG", payload: el });
  };
};

export const updateUserDetails = (user) => {
  return (dispatch) => {
    dispatch({ type: "UPDATE_USER_UID", payload: user.uid });
  };
};

export const resetLoginPage = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_LOGIN_PAGE" });
  };
};
export const resetRegisterPage = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_REGISTER_NEW_USER_PAGE" });
  };
};

export const resetForgotPasswordPage = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_FORGOT_PASSWORD_PAGE" });
  };
};

export const signOut = () => {
  // let auth = firebase.auth();
  return (dispatch) => {
    dispatch({ type: "LOGOUT_SUCCEEDED" })
    dispatch({ type: "RESET_APP" })
  };
};

export const serviceAccessCode = ({ accessCode, uid }) => {
  return (dispatch) => {
    let requestOptions = {
      method: "POST",
      body: JSON.stringify({ accessCode, uid, app: "learning-assessment" }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
  };
};

export const resetToast = () => {
  return (dispatch) => {
    dispatch({ type: "MODULES_ADDED_RESET" });
  };
};

export const setToast = () => {
  return (dispatch) => {
    dispatch({ type: "MODULES_ADDED" });
  };
};

const loginAttempFail = (dispatch, err, failureCallback) => {
  dispatch({ type: "LOGIN_FAILED", payload: err.message });
  failureCallback();
};

const loginAttemptSuccess = (dispatch, result, successCallBack) => {
  dispatch({ type: "LOGIN_SUCCEEDED", payload: result });
  successCallBack();
}

function getErrorMsg(error) {
  let { code } = error;

  switch (code) {
    case "auth/invalid-email":
      error.message = "Please enter a valid email id";
      break;
    case "auth/user-not-found":
      error.message = "This email id is not registered. Please register.";
      break;
    case "auth/wrong-password":
      error.message = "Incorrect email or password. Please try again.";
      break;
    case "auth/email-already-in-use":
      error.message =
        "This email id is already registered. Please use a different email id";
      break;
    default:
      break;
  }

  return error;
}

function setErrorMsgInForgotPassword(dispatch, msg) {
  dispatch({ type: "SET_RESET_PASSWORD_ERROR_MSG", payload: msg });

  setTimeout(() => {
    dispatch({ type: "SET_RESET_PASSWORD_ERROR_MSG", payload: " " });
  }, 3000);
}

export function getAccessTokenDetails({ accesscode, email }) {
  return (dispatch) => {
    dispatch({ type: "GETACCESSTOKENDETAILS_ATTEMPT", payload: true });
    return fetch(
      config.apiurl + "accesscode/getAccessCodeForId/" + accesscode,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      }
    )
      .then((res) => res.json())
      .then((resp) => {
        console.log(resp);
        return dispatch(getAccessTokenDetailsSuccess(resp));
      })
      .catch((err) => dispatch(getAccessTokenDetailsFailure(err)));
  };
}

function getAccessTokenDetailsSuccess(data) {
  return { type: "GETACCESSTOKENDETAILS_SUCCEEDED", data };
}
function getAccessTokenDetailsFailure(err) {
  return { type: "GETACCESSTOKENDETAILS_FAILURE" };
}

const registerAttempSuccess = (dispatch, user, successCallBack) => {
  dispatch({ type: "REGISTER_SUCCEEDED", payload: user });
  successCallBack(user);
};

export function subscibe(studentId) {
  return (dispatch) => {
    var requestOptions = {
      method: 'POST',
      redirect: 'follow'
    };

    return fetch(config.studenturl + "purchaseorder/subscribe/" + studentId, requestOptions)
      .then(response => response.json())
      .catch(error => console.log('error', error));
  }
}

export function verifyAccessCode(accesscode) {

  return async (dispatch) => {
    if (accesscode.trim() === "") {
      setErrMsg("Access Code cannot be blank")
      return;
    }
    else {
      setErrMsg("")
    }
    let token;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "email": "su@ggmail.com",
      "password": "acs12345"
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    await fetch(config.apiurl + "/auth/login", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.code) {
          dispatch(setErrMsg(result.message))
          return
        }
        else {
          dispatch({ type: "UPDATE_TOKEN", payload: result["tokens"]["access"]["token"] })
          token = result["tokens"]["access"]["token"]
        }
      })
      .catch(error => console.log('error', error));

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    let org;

    requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders
    };

    dispatch({ type: "GETACCESSTOKENDETAILS_ATTEMPT" })

    await fetch(config.studenturl + "/accesscode/" + accesscode, requestOptions)
      .then(response => response.json())
      .then((result) => {
        console.log(result)
        if (result.code) {
          dispatch(setErrMsg(result.message))
          return;
        }
        else {
          dispatch(setErrMsg(""))
          org = result["organizationid"]
          dispatch({ type: "GETACCESSTOKENDETAILS_SUCCEEDED", data: result })
          dispatch(getColleges(token, org))
        }
      })
      .catch(error => console.log('error', error));
  }
}

export function getColleges(token, organizationid) {
  return (dispatch) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(config.apiurl + "organization/parent/" + organizationid, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.code) {
          dispatch(setErrMsg(result.message))
          return;
        }
        else {
          dispatch(setErrMsg(""))
          dispatch({ type: "COLLEGE_DATA", payload: result })
        }
      })
      .catch(error => console.log('error', error));
  }
}

export function resetApp() {
  return (dispatch) => {
    dispatch({ type: "RESET_APP" })
  }
}

export async function getOrganisationByParentId(data) {
  console.log(data)
  store.dispatch({ type: "GET_ORGANISATIONBYPARENTID_ATTEMPT", payload: true });
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  try {
    const response = await fetch(config.apiurl + "organization/parent/" + data.parentOrgId, requestOptions);
    const response_1 = await response.json();
    store.dispatch({ type: "GET_ORGANISATIONBYPARENTID_SUCCESS", payload: response_1 });
  } catch (error) {
    store.dispatch({ type: "GET_ORGANISATIONBYPARENTID_FAILED", payload: true }); console.log('error', error);
  }

}
export async function updateUserByUserId(data, val) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);
  myHeaders.append("Content-Type", "application/json");
  store.dispatch({ type: "UPDATE_USER_BY_ID_ATTEMPT", payload: true });
  console.log(val)
  var raw = JSON.stringify({
    ...val
  });

  var requestOptions = {
    method: 'PATCH',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  try {
    // const response = await fetch("http://localhost:5001/v1/" + "users/" + data.userId, requestOptions);    
    fetch(config.studenturl + "student/" + data.user_id, requestOptions)
      .then((response) => {
        return response.json()
      })
      .then((result) => {
        if ('code' in result) throw Error(result.message)
        store.dispatch({ type: "UPDATE_USER_BY_ID_SUCCESS", payload: result });
      })
      .catch((error) => console.log(error))
  } catch (error) {
    store.dispatch({ type: "UPDATE_USER_BY_ID_FAILURE" });
    return console.log('error', error);
  }
}

export async function createOrganization(data, val) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.user_token);
  myHeaders.append("Content-Type", "application/json");
  store.dispatch({ type: "CREATE_ORGANISATION_ATTEMPT" });
  var raw = JSON.stringify({
    ...val,
    coachId: data.userId
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  try {
    const response = await fetch(config.apiurl + "organization", requestOptions);
    const response_1 = await response.json();
    store.dispatch({ type: "CREATE_ORGANISATION_SUCCESS", payload: response_1 });
    return response_1;
  } catch (error) {
    store.dispatch({ type: "CREATE_ORGANISATION_FAILURE" });
    return console.log('error', error);
  }
}

export async function updateUserByIdChange() {
  await store.dispatch({ type: "UPDATE_USER_BY_ID_CHANGE" });
}

export const callAPI = async (token, method, url, body) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method,
    headers: myHeaders,
    body: JSON.stringify(body),
    redirect: 'follow'
  };

  return await fetch(url, requestOptions).then(response => response.json())
}

export const loginWithToken = (dispatch, token) => {
  return callAPI(token, "POST", `${config.apiurl}auth/loginWithToken`, {}).then(result => {
    if (result.code)
      loginAttempFail(dispatch, result, () => { })
    getStudentByEmail(dispatch, result.tokens.access.token, result.user.email)
    loginAttemptSuccess(dispatch, result, () => { })
  })
    .catch(error => console.log('error', error));
}