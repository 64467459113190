import React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  Button,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import "./LoginForm.css";

const styles = {};

class Loginform extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showPassword: false,
      fields: {},
      errors: {},
      label: "",
    };

    this.handleChange = (prop) => (event) => {
      this.setState({ [prop]: event.target.value });
    };

    this.handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    this.handleClickShowPassword = () => {
      this.setState({ showPassword: !this.state.showPassword });
    };
  }

  handleValidation(eml, pwd) {
    this.setState({ label: "" });
    let errors = {};
    let formIsValid = true;

    //Email
    if (!eml) {
      formIsValid = false;
      errors["user-email"] = "Email cannot be empty";
      this.setState({ label: "Email can not be empty" });
    }
    //Password
    if (!pwd) {
      formIsValid = false;
      errors["user-password"] = "Password cannot be empty";
      this.setState({ label: "Password can not be empty" });
    }

    if (typeof eml !== "undefined") {
      let lastAtPos = eml.lastIndexOf("@");
      let lastDotPos = eml.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          eml.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          eml.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["user-email"] = "Email is not valid";
        this.setState({ label: "Email can not be empty" });
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  render() {
    const { classes } = this.props;
    const toRegister = (props) => <Link to="../auth/register" {...props} />;
    const toResetPassword = (props) => (
      <Link to="../auth/reset-password" {...props} />
    );
    return (
      <div className={"auth-form-cntr"}>
        <form>
          <div className={"loginform-cntr"}>
            <FormControl fullWidth className={"input-cntr"}>
              <InputLabel htmlFor="adornment-password">Email</InputLabel>
              <Input
                id="user-email"
                label="Email"
                type="text"
                autoComplete="on"
                value={this.state.email}
                onChange={this.handleChange("email")}
              />
            </FormControl>
            <FormControl fullWidth className={"input-cntr"}>
              <InputLabel htmlFor="adornment-password">Password</InputLabel>
              <Input
                id="user-password"
                label="Password"
                autoComplete="off"
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.password}
                onChange={this.handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <div className="errorMsg">{this.state.label}</div>
            <div className="errorMsg">{this.props.authMsg}</div>
            <FormControl
              className={"input-cntr"}
              style={{ display: "flex", alignItems: "flex-end" }}
            >
              <Link
                className="forgot-pw"
                // component={Link}
                to="/auth/reset-password"
                style={{
                  margin: "10px 0",
                  textDecoration: "none",
                  color: "#b9b9b9",
                  fontSize: ".815rem",
                }}
              >
                Forgot Password
              </Link>
            </FormControl>
            <div className="rl-btns">
              <FormControl
                fullWidth
                className={"input-cntr"}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  className="loginBtn submit-btn"
                  style={{
                    background: "#e6111b 0% 0% no-repeat padding-box",
                    color: "white",
                    width: "200px",
                    height: "45px",
                    marginTop: "35px",
                  }}
                  onClick={() => {
                    let { email, password } = this.state;
                    let chkVal = this.handleValidation(email, password);

                    if (chkVal) {
                      this.props.loginAttempt({
                        email,
                        password,
                        successCallBack: () => {
                          //alert('success');
                        },
                        failureCallback: () => {
                          //alert('failed');
                        },
                      });
                    } else {
                      this.setState({
                        label:
                          "Either Email or Password is incorrect. Please try again.",
                      });
                    }
                  }}
                >
                  Login
                </Button>
              </FormControl>
              <FormControl
                fullWidth
                className={"input-cntr login-link-cntr"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "10px 0",
                  color: "#e6111b",
                }}
              >
                <Link
                  className={classes.button}
                  to="/auth/register"
                  style={{ color: "#e6111b", textDecoration: "none" }}
                >
                  Register
                </Link>
              </FormControl>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

Loginform.propTypes = {
  classes: PropTypes.object.isRequired,
  loginAttempt: PropTypes.func,
  authMsg: PropTypes.string,
};

export default withStyles(styles)(Loginform);
