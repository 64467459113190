import store from "../../Redux_Store";
import { signOut } from "../auth/redux_actions";
import Config from "../../config";

const config = {
  // appurl: "http://localhost:5000/v1/student/"
  appurl: Config.API_URL + "student/",
  // http://uat.pedgog.in:3000/v1/student/"
};

export const getModules = (token, user_uid) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(
    config.appurl + "student/getModuleByStudentId/" + user_uid,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      if (result.code === 401) store.dispatch(signOut());
      else {
        var i;
        for (i = 0; i < result[0]["AvailableModule"].length; i++) {
          getModuleDetail(token, result[0]["AvailableModule"][i]);
        }
      }
    })
    .catch((error) => console.log("error", error));
};

export const setCurrentModule = (md) => {
  store.dispatch({ type: "SET_CURRENT_MODULE", payload: md });
};

export const getModuleDetail = (token, moduleId) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(config.appurl + "module/getModuleById/" + moduleId, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      console.log(result);
      if (result.code === 401) store.dispatch(signOut());
      else {
        // console.log(result[0])
        store.dispatch({
          type: "UPDATE_MODULES",
          data: result[0],
        });
      }
    })
    .catch((error) => console.log("error", error));
};

export const tabIndexChange = (index) => {
  return {
    type: "TAB_INDEX_CHANGE",
    value: index,
  };
};

export const checkOTP = (data) => {
  store.dispatch({ type: "CHECKOTP_ATTEMPT" });
  console.log(data);
  return fetch(config.appurl + "otp/checkOTP", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: "bearer " + data.user_token,
      accept: "application/json",
    },
    body: JSON.stringify({
      // moduleId: data.module_Id,
      otp: data.otp,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      console.log(response);
      if (response.code === 401) return store.dispatch(signOut());
      else if (!response.code)
        return store.dispatch({ type: "CHECKOTP_SUCCEDED", payload: response });
      throw Error(response.stack);
    })
    .catch((err) => {
      store.dispatch({ type: "CHECKOTP_FAILURE", payload: err });
      console.log("error ===", err);
    });
};

export const reset = () => {
  store.dispatch({ type: "RESET_ENABLE_ENGAMENT" });
};

export const enableEngagement = (engagementId) => {
  store.dispatch({ type: "ENABLE_ENGAGEMENT", payload: engagementId });
};

export const disableEngagement = (engagementId) => {
  store.dispatch({ type: "DISABLE_ENGAGEMENT", payload: engagementId });
};

export const getEngagement = (token, engagementId) => {
  return fetch(config.appurl + `engagement/${engagementId}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: "bearer " + token,
      accept: "application/json",
    },
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.code === 401) return store.dispatch(signOut());
      return response;
    })
    .catch((err) => {
      // store.dispatch({ type: 'CHECKOTP_FAILURE', payload: err })
      console.log("error ===", err);
    });
};

export const logEvent = (token, studentId, eventName, eventData) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    studentId,
    eventName,
    eventData,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(config.appurl + "logevent", requestOptions)
    // fetch(config.appurl + "logevent", requestOptions)
    .catch((error) => console.log("error", error));
};

export const callAPI = async (token, method, url, body) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method,
    headers: myHeaders,
    body: body && JSON.stringify(body),
    redirect: "follow",
  };

  return await fetch(url, requestOptions).then((response) => response.json());
};

export const getHeaders = async (url) => {
  return await callAPI(undefined, "GET", url, undefined);
};
