import firebase from "firebase/app";
import 'firebase/database'
import config from "../../config"

const io = require("socket.io-client");

var firebaseConfig = {
    apiKey: "AIzaSyCEt3wyrOuWlNmbg9C3O40DzhgOT3MWvFk",
    authDomain: "illumine-socket-test.firebaseapp.com",
    databaseURL: "https://illumine-socket-test-default-rtdb.firebaseio.com",
    projectId: "illumine-socket-test",
    storageBucket: "illumine-socket-test.appspot.com",
    messagingSenderId: "509881491143",
    appId: "1:509881491143:web:bdfcb471a81883797868e2"
};

// var socket;
var stop = true;
var eventQueue = []
var eventTypeAndHandler, senderType, senderId, _otp, receiveId, sessionid, _receiveType, _engagementId, enableEngagementRef, engagementRef

firebase.initializeApp(firebaseConfig);
const database = firebase.database()
let dataRef = database.ref()

async function initalize(engagementId, etah, userType, userId, receiveType, otp) {
    // Stoing Variables
    eventTypeAndHandler = etah
    senderType = userType
    senderId = userId
    _receiveType = receiveType
    _engagementId = engagementId

    //Todo: Fetching Session Detail Using OTP
    const session = await getSessionDetail(otp)
    sessionid = session.sessionid
    receiveId = sessionid

    enableEngagementRef = dataRef.child(sessionid).child("enableEngagement")
    engagementRef = dataRef.child(sessionid).child(_engagementId)
    startEngagement()
}

async function init(etah, userType, username, userId, receiveType, otp) {
    // Stoing Variables
    eventTypeAndHandler = etah
    senderType = userType
    senderId = userId
    _receiveType = receiveType

    //Todo: Fetching Session Detail Using OTP
    const session = await getSessionDetail(otp)

    if (session) {
        sessionid = session.sessionid

        receiveId = sessionid
        enableEngagementRef = dataRef.child(sessionid).child("enableEngagement")
        getEngableEngagement()        
        userJoin(username)
        startEngagement()
    }
}

async function startEngagement() {
    receiveEvent()
    loop()
}

async function getSessionDetail(otp) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        otp
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    let session
    await fetch(config.API_URL + "session/getSessionByOTP", requestOptions)
        .then(response => response.json())
        .then(result => { if (!result.code) return session = result })
        .catch(error => console.log('error', error));

    console.log(session)

    return session;
}

function receiveEvent() {
    stop = false
    if (_engagementId)
        engagementRef.on("child_added", (snapshot) => {
            let payload = snapshot.val()
            eventQueue.push(payload)
        })
    else {
        enableEngagementRef.on('child_changed', (snapshot) => {
            let payload = snapshot.val()
            console.log(snapshot.key)
            eventQueue.push(payload)
        })
    }
}

async function loop() {
    while (!stop || eventQueue.length > 0) {
        // console.log(eventQueue)
        let first = eventQueue.shift();
        console.log(first)
        if (first)
            eventTypeAndHandler(first);
        else
            await sleep(1)
        //Sleep for 10 Sec
    }
}

function sendEvent(eventName, eventData) {
    let payload = {
        senderType, senderId, eventName, eventData, receiveType: _receiveType, receiveId, eventName, eventData, engagementId: _engagementId
    }
    dataRef.child(sessionid).child(_engagementId).push(payload)
}


function stopEngagement() {
    stop = true;
    engagementRef.off()
    enableEngagementRef.off()

    console.log("Socket Disconnect")
}

function sleep(second) {
    return new Promise(resolve => setTimeout(resolve, second * 1000));
}

function userJoin(username) {
    let now = new Date();
    dataRef.child(sessionid).child('participants').child(username).child(now.getTime()).set(true)
}

function getEngableEngagement() {
    enableEngagementRef.on('child_added', (snapshot) => {
        let payload = snapshot.val()
        eventQueue.push(payload)
    })
    enableEngagementRef.off()
}

export default {
    init,
    initalize,
    sendEvent,
    stopEngagement,
    startEngagement
}