import React, { useEffect } from "react";
import { Player } from 'video-react';
import "video-react/dist/video-react.css";

let VideoPlayer = ({ imgUrl, mediaUrl, uid, token, logEvent, resourceId, moduleId }) => {
  useEffect(() => {
    logEvent(token, uid, "RESOURCE_ACCESSED", { resourceId, moduleId })
  }, [])
  return <Player
    playsInline
    // poster={imgUrl || "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FTB-thumb.PNG"}
    src={mediaUrl || "https://s3.ap-south-1.amazonaws.com/possibilities-project/teasers/FacethebruteV4.mp4"}
    fluid={false}
    width={'100%'}
    height={'100%'}
    style={{ border: 'none' }}
  />
}

export default VideoPlayer;