import React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  Button,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";

// import { sideNavData } from "./tileData";
// import ForgotPassword from "../../containers/ForgotPassword";
import "./Registration.css";

const styles = {};

class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showPassword: false,
      accesscode: "",
      fullname: "",
      label: "",
      college: '',
      collegeName: '',
      course: '',
      semester: '',
      courseText: ''
    };

    this.handleChange = (prop) => (event) => {
      this.setState({ [prop]: event.target.value });
    };

    this.handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    this.handleClickShowPassword = () => {
      this.setState({ showPassword: !this.state.showPassword });
    };

    this.handleComboDataChange = (field, option, newValue) => {
      this.setState({
        [field]: {
          ...this.state[field],
          [option]: newValue
        }
      })
    }
  }

  componentDidMount() {
    this.props.resetApp()
  }

  register = () => {
    const { accesscode, email, password, fullname, semester } = this.state
    let organization = this.props.auth.collegeData.organization && this.props.auth.collegeData.organization[this.state.college]
    if (accesscode.trim() == "") {
      this.props.setErrMsg("Access Code cannot be blank")
      return;
    }
    if (organization) {
      let collegeName = this.state.collegeName.trim() !== "" ? this.state.collegeName : organization.organizationname
      if (collegeName == "Other") {
        this.props.setErrMsg("Please Enter College Name")
        return;
      }
      let organizationid = organization.id
      let course = this.state.courseText.trim() !== "" ? this.state.courseText : this.state.course
      if (course.trim() == "") {
        this.props.setErrMsg("Please Select Course")
        return;
      }
      if (course == "Other") {
        this.props.setErrMsg("Please Enter Course")
        return;
      }
      if (semester === "") {
        this.props.setErrMsg("Please Enter Semester")
        return
      }
      this.props.registerAttempt({
        email,
        password,
        accesscode,
        collegeName,
        organizationid,
        semester,
        course,
        fullname,
        parentid: this.props.auth.accesscodeData.organizationid,
        successCallBack: (user) => {
          this.props.subscibe(user.studentid)
          this.props.loginAttempt({ email, password })
        },
        failureCallback: () => {
          //alert('failed');
        }
      });
    }
    else {
      this.props.setErrMsg("Please Select College or Enter valid Access Code")
      return;
    }
  }

  render() {

    return (
      <div className={"auth-form-cntr"}>
        {console.log(this.state)}
        {this.state.label ?
          (<div className="errorMsg">
            <label> {this.state.label} </label>
          </div>) : null
        }
        <form>
          <div className="registerform-cntr">
            <FormControl fullWidth className={"input-cntr"}>
              <InputLabel htmlFor="adornment-password">Email</InputLabel>
              <Input
                id="user-email"
                label="Email"
                type="text"
                autoComplete="on"
                value={this.state.email}
                onChange={this.handleChange("email")}
              />
            </FormControl>
            <FormControl fullWidth className={"input-cntr"}>
              <InputLabel htmlFor="adornment-password">Password</InputLabel>
              <Input
                id="user-password"
                label="Password"
                autoComplete="off"
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.password}
                onChange={this.handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl
              fullWidth
              className={"input-cntr"}
            >
              <InputLabel htmlFor="adornment-password">Access Code </InputLabel>
              <Input
                id="accesscode"
                label="Access Code"
                type="text"
                autoComplete="on"
                value={this.state.accesscode}
                onChange={this.handleChange("accesscode")}
                onBlur={() => {
                  this.props.verifyAccessCode(this.state.accesscode);
                }}
              />
            </FormControl>

            <FormControl fullWidth className={"input-cntr"}>
              <InputLabel htmlFor="adornment-password">Full name</InputLabel>
              <Input
                id="fullname"
                label="Full Name"
                type="text"
                value={this.state.fullname}
                onChange={this.handleChange("fullname")}
              />
            </FormControl>

            {this.state.accesscode && this.props.auth.collegeData && this.props.auth.collegeData.organization && this.props.auth.collegeData.organization.length > 0 ?
              <FormControl fullWidth className={"input-cntr"}>
                <InputLabel htmlFor="adornment-password">College</InputLabel>
                <Select label="College" value={this.state.college} onChange={this.handleChange("college")}>
                  {this.props.auth.collegeData.organization.map((college, index) =>
                    <MenuItem key={index} value={index}> {college.organizationname}</MenuItem>
                  )}
                </Select>
              </FormControl>
              : ""}
            {/* {this.props.auth.collegeData && this.props.auth.collegeData.organization && this.props.auth.collegeData.organization.length > 0 &&

              this.props.auth.collegeData.organization.map((college, index) =>
                console.log(college, index)
              )

            } */}


            {this.state.accesscode && this.props.auth.collegeData && this.props.auth.collegeData.organization && this.props.auth.collegeData.organization[this.state.college] && this.props.auth.collegeData.organization[this.state.college].organizationname === "Other" ? <FormControl fullWidth className={"input-cntr"}>
              <InputLabel htmlFor="adornment-password">Other College Name</InputLabel>
              <Input
                id="college"
                label="College Name"
                type="text"
                value={this.state.collegeName}
                onChange={this.handleChange("collegeName")}
              />
            </FormControl> : ""}
            {console.log(this.props.auth.collegeData)}
            {this.state.accesscode && this.props.auth.collegeData && this.props.auth.collegeData.course && this.props.auth.collegeData.course.length > 0 ?
              <FormControl fullWidth className={"input-cntr"}>
                <InputLabel htmlFor="adornment-password">Course</InputLabel>
                <Select label="Stream" value={this.state.course} onChange={this.handleChange("course")}>
                  {this.props.auth.collegeData && this.props.auth.collegeData.course && this.props.auth.collegeData.course.map((s, index) =>
                    <MenuItem key={s} value={s}>{s}</MenuItem>
                  )}
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl> : ""}

            {this.state.accesscode && this.state.course === "Other" ?
              <FormControl fullWidth className={"input-cntr"}>
                <InputLabel>Other Course</InputLabel>
                <Input
                  id="course"
                  label="Course"
                  type="text"
                  value={this.state.courseText}
                  onChange={this.handleChange("courseText")}
                /></FormControl> : ""}

            {this.state.accesscode && this.props.auth.collegeData && this.props.auth.collegeData.course && this.props.auth.collegeData.course.length > 0 && <FormControl fullWidth className={"input-cntr"}>
              <InputLabel htmlFor="adornment-password">Semester</InputLabel>
              <Select label="Semester" value={this.state.semester} onChange={this.handleChange("semester")}>
                {["Semester 1", "Semester 2", "Semester 3", "Semester 4", "Semester  5", "Semester 6", "Semester 7", "Semester 8"].map((s, index) =>
                  <MenuItem id={s} value={s}>{s}</MenuItem>
                )}
              </Select>
            </FormControl>}

            <div className="errorMsg">{this.props.authMsg}</div>
            <FormControl
              className={"input-cntr"}
              style={{ display: "flex", alignItems: "flex-end" }}
            >
              <Link
                className="forgot-pw"
                // component={Link}
                to="/auth/reset-password"
                style={{
                  margin: "10px 0",
                  textDecoration: "none",
                  color: "#b9b9b9",
                  fontSize: ".815rem",
                }}
              >
                Forgot Password
              </Link>
            </FormControl>
            <div className="rl-btns">
              <FormControl
                fullWidth
                className={"input-cntr"}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  className="RegistrationBtn"
                  style={{
                    background: "#e6111b 0% 0% no-repeat padding-box",
                    color: "white",
                    width: "200px",
                    height: "45px",
                    marginTop: "35px",
                  }}
                  onClick={() => this.register()}
                >
                  Register
                </Button>
              </FormControl>
              <FormControl
                fullWidth
                className="login-link-cntr"
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "10px 0",
                  color: "#e6111b",
                }}
              >
                <Link
                  className="login-link"
                  to={"/auth/login"}
                  style={{ color: "#e6111b", textDecoration: "none" }}
                >
                  Login
                </Link>
              </FormControl>
            </div>
          </div>
        </form >
      </div >
    );
  }
}

Registration.propTypes = {
  classes: PropTypes.object.isRequired,
  registerAttempt: PropTypes.func,
  authMsg: PropTypes.string,
  auth: PropTypes.object
};

export default withStyles(styles)(Registration);
