import React from "react";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import AccessCode from "./p_AccessCode";
import { useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuOpen from '@material-ui/icons/MenuRounded';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import FirstTimeUser from './firstTimeUserMsg';
import { getModules, logEvent } from "./redux_actions";
import { getOrganisationByParentId } from '../auth/redux_actions'
import { useEffect } from "react";

import Add_CollegeName_Popup from "../../components/Add_CollegeName_Popup/index";
import Add_CollegeName_Success from "../../components/Add_CollegeName_Popup/success";

import './explore.css'
const Resources = (props) => {

  // const history = useHistory();    
  const isDesktop = useMediaQuery("(min-width:980px)");
  let myModules = useSelector((state) => state.app.myModules);
  let auth = useSelector((state) => state.auth);
  let app = useSelector((state) => state.app);
  let userId = useSelector((state) => state.app.userId);

  useEffect(() => {
    if (Object.keys(auth.collegeData).length === 0 && auth.userAccount.organizationid)
      getOrganisationByParentId({ parentOrgId: auth.userAccount.organizationid, user_token: auth.user_token })
    getModules(auth.user_token, auth.user_uid)
    logEvent(auth.user_token, auth.userAccount.id, "VISIT_HOME", {})
  }, [])

  const [md, setMd] = React.useState(0);
  const [mobileNavOpen, setMobileNavOpen] = React.useState(false);
  const [isAccessCodeOpen, setAccessCodeOpen] = React.useState(false);
  var w = window.innerWidth;
  var h = window.innerHeight;
  var deviceRatio = w / h;
  let mdCntrWd = w - 2 * 24 - 200 - 96;

  function mobileNav(items, value, handleChange) {
    if (items)
      return <div>
        <div style={{
          height: 64, backgroundColor: '#F7F7F7', display: 'flex',
          justifyContent: 'center', alignItems: 'center', padding: '0px 24px', margin: '0px -24px'
        }} onClick={() => { setMobileNavOpen(true) }}>
          <div style={{ flexGrow: 1 }}>
            <div style={{ fontSize: 10, marginBottom: 6, color: '#8C8C8C' }}>Select a module</div>
            <div style={{ fontSize: 15, fontWeight: 700, textTransform: 'capitalize' }}>Module: {md + 1}</div>
          </div>
          <MenuOpen fontSize='large' />
        </div>
        <Dialog onClose={() => { setMobileNavOpen(false) }} open={mobileNavOpen}>
          <DialogTitle>Select a module</DialogTitle>
          <List>
            {Object.keys(items || {}).sort().map((el, i) => (
              <ListItem selected={i === md} button onClick={() => { setMd(i); setMobileNavOpen(false) }} key={el}>
                {/* <ListItemAvatar><div>{i+1}</div></ListItemAvatar> */}
                <ListItemText primary={items[el].moduleId.title} style={{ fontSize: 15, fontWeight: 700, }} />
              </ListItem>
            ))}
          </List>
        </Dialog>
      </div>
  }

  function desktopNav(items, value, handleChange) {
    if (items)
      return <div style={{ width: 200 }}>
        <div style={{ fontSize: 13, padding: '16px 0px 8px', fontWeight: 700, textTransform: 'capitalize' }}>Jump To</div>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value || 0}
          onChange={handleChange}
          aria-label="jump-to"
        >
          {Object.keys(items || {}).sort().map((el, i) => {
            console.log(el)
            let item = items[el];
            let a = <div style={{ textAlign: 'left', width: '100%', textTransform: 'initial' }}>
              <div style={{ fontSize: 9, fontWeight: 500, paddingBottom: 4 }}>Module: {i + 1}</div>
              <div style={{ fontSize: 12, fontWeight: 700 }}>{item.moduleId.title}</div>
            </div>
            return <Tab key={el} label={a} style={{ padding: '16px 16px 16px 0px', borderTop: '1px solid #999' }} />
          })}
        </Tabs>
      </div>
  }

  function moduleCntr(items, value) {
    return <div style={{ flexGrow: 1 }}>
      {Object.keys(items || {}).sort().map((el, i) => {
        let item = items[el];
        return <div key={el} role="tabpanel" hidden={value !== i} id={`vertical-tabpanel-${i}`} value={value} index={i} style={{ padding: `0px 0px 0px ${isDesktop ? 96 : 0}px` }}>
          {/* <Link to='/app/live-session/otp' className='live-session-button'><div className='align-center'><span className='live-session-dot'>&#9679;</span>Live Session</div></Link> */}
          <div style={{ fontSize: 13, padding: '16px 0px 8px', fontWeight: 700, textTransform: 'capitalize' }}>Module: {i + 1}</div>
          <div style={{ padding: '0px 0px 16px', fontSize: 20, fontWeight: 700, lineHeight: 1.4 }}>{item.moduleId.title}</div>
          <div style={{ display: 'flex', margin: '0px -16px', flexWrap: 'wrap' }}>
            {Object.keys(item.units || {}).map(el => {
              let unit = item.units ? item.units[el] : {};
              if (unit.type === "live_session") return
              return <Link key={el} to={unit.type === "live_session" ? `/app/live-session/${item.id}/otp` : `/app/${item.id}/resource/${item.units[el]["id"]}`} style={{ width: 328, border: '1px solid #DCDCDC', display: 'block', textDecoration: 'none', margin: 16 }}>
                <div>
                  <div style={{ backgroundColor: '#ECECEC', display: 'flex' }}>
                    <img src={unit && unit.icon ? unit.icon : null} width='100%' height='auto' />
                  </div>
                  <div style={{ padding: 16 }}>
                    <div style={{ fontSize: 14, color: '#999', paddingBottom: 8 }}>{unit && unit.description ? unit.description : ''}</div>
                    <div style={{ color: '#000', fontWeight: 700 }}>{unit && unit.unitTitle ? unit.unitTitle : ''}</div>
                  </div>
                </div>
              </Link>
            })}
          </div>
        </div>
      })
      }
    </div >
  }

  // const sortObject = obj => obj && Object.keys(obj).sort().reduce((res, key) => (res[key] = obj[key], res), {})
  return <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', flexGrow: '1' }}>
    <Header />
    {auth.collegeData && auth.collegeData.organization && auth.collegeData.organization.length > 0 && <Add_CollegeName_Popup app={app} auth={auth} />}
    {auth.updatedUser === true && <Add_CollegeName_Success app={app} auth={auth} />}
    {/* <Add_CollegeName_Success app={app} auth={auth} /> */}
    <div style={{ display: 'flex', flexDirection: isDesktop ? 'row' : 'column', padding: 24 }}>
      {isDesktop
        ? desktopNav(myModules, md, (ev, val) => { setMd(val) })
        : mobileNav(myModules, md, (ev, val) => { setMd(val) })}
      {myModules ? <div>
        {moduleCntr(myModules, md)}
        <div style={{ float: 'right' }} onClick={() => { setAccessCodeOpen(true) }}>
          {/* <AddCircleIcon style={{ fontSize: 40, fill: '#7CBA00' }} /> */}
        </div>
      </div> : <FirstTimeUser user={{ uid: userId }} />}
    </div>
    <Footer />
    <Dialog open={isAccessCodeOpen} onClose={() => { setAccessCodeOpen(false) }}>
      <AccessCode user={{ uid: userId }} onSuccess={() => { setAccessCodeOpen(false) }} onFailure={() => { setAccessCodeOpen(false) }} />
    </Dialog>
  </div>;
};


export default Resources;