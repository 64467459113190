import React, { Component, useEffect } from 'react';
import { useSelector } from "react-redux";
import MultiPageTemplate from '../../components/MultiPagePDFReader/index';
import DownloadablePDF from '../../components/downloadable_pdf';
import VideoPlayer from '../../components/VideoPlayer';
import Links from '../../components/Links';
import OTPScreen from '../../components/OTPScreen';

import { getModules, logEvent } from './redux_actions';
import { Link } from 'react-router-dom';

const duringLoad = <div className='loading-screen'>Loading...</div>

let Unit = (props) => {
  let { moduleId, resourceId } = props.match.params;
  let app = useSelector((state) => state.app);
  let auth = useSelector((state) => state.auth);

  useEffect(() => {
    getModules(auth.user_token, auth.user_uid)
  }, [])

  let uid = auth.userAccount.id;
  let user_token = auth.user_token;

  let units = app.myModules?.[moduleId]?.units;
  var un, unit;
  for (un in units) {
    if (units[un]['id'] === resourceId) {
      unit = units[un]
      break;
    }
  }

  if (unit)
    return serveTemplate(unit, resourceId, uid, app.myModules[moduleId]['moduleId'].id, user_token, app, auth)
  else
    return <div>Loading...</div>
}

function serveTemplate(unit, resourceId, uid, moduleId, user_token, app, auth) {
  let src = unit.src, docRatio = unit.docRatio || null;
  console.log(unit);
  if (unit.type == 'pdf_downloadable') {
    return <DownloadablePDF src={src} logEvent={logEvent} resourceId={resourceId} uid={uid} token={user_token} moduleId={moduleId} />
  } else if (unit.type == 'external_video') {
    return <div style={{ width: '100%', height: '100%' }}>
      <VideoPlayer mediaUrl={src} logEvent={logEvent} resourceId={resourceId} uid={uid} token={user_token} moduleId={moduleId} />
    </div>
  } else if (unit.type == 'links' || unit.type == 'web-url') {
    return <Links unit={unit} logEvent={logEvent} resourceId={resourceId} uid={uid} token={user_token} moduleId={moduleId} />
  }
  // else if (unit.type === 'live_session') {
  //   return <OTPScreen moduleId={moduleId} user_token={user_token} app={app} auth={auth} />
  // }
  else {
    return <div id='_book_meta_cntr' className='_book_meta_cntr'>
      <MultiPageTemplate src={src} logEvent={logEvent} duringLoad={duringLoad} docRatio={docRatio} resourceId={resourceId} uid={uid} token={user_token} moduleId={moduleId} />
    </div>
  }
}
export default Unit;