import React, { useState, useEffect } from 'react'
import { Button } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import logo from './logo-home.svg'
import { disableEngagement, enableEngagement, getEngagement } from '../../containers/app/redux_actions';
import { useParams } from 'react-router-dom';
import Engagement from '../../Engagements/index';
// import { joinRoom, startStudent, stopStudent } from '../../containers/app/Socket';
import './style.css';
import Interaction from "../../containers/app/Interaction"
import { useSelector } from 'react-redux';

function etah(event) {
    console.log(event)
    switch (event.eventName) {
        case "START":
            enableEngagement(event.eventData)
            break;
        case "STOP":
            disableEngagement(event.eventData)
            break;
    }
}


export default ({ app, auth }) => {
    let moduleId;
    // console.log(moduleId);
    useEffect(() => {
        // moduleId = app.otp.moduleId;
        console.log(app);
        // joinRoom(app.otp.sessionId, auth.user_uid)
        // startStudent();
        // stopStudent();
    }, [])
    // const otp = useSelector((state) => state.app.otp.otp)
    const [eng, setEng] = useState('');
    const handleClick = (item) => {
        getEngagement(auth.user_token, item.engagement).then((response) => setEng(response))
    }

    useEffect(() => {
        Interaction.init(etah, "Student", auth.userAccount.fullname, auth.userAccount.id, "Coach", app.otp.otp)
    }, [])

    return (
        <Grid container justify='center' style={{ height: '100%', backgroundColor: "#FAFAFA" }}>
            <Grid item xs={12} sm={6} style={{ height: '100%' }}>
                <div className='eng-screen-bg'>
                    <div className="header" >
                        <div className="header_info">
                            <img src={logo} style={{ width: '80%', height: '80%' }} />
                            <div style={{ fontSize: "120%", marginRight: "30%", fontWeight: "bold" }}>MyApp</div>
                        </div>
                    </div>
                    {!eng ? <div style={{ width: '100%', height: '81%' }}>
                        <div className='engList-scrollable-darkgray' style={{ color: '#c1330b', padding: '0px 10px', maxHeight: '20%', overflowY: 'auto' }} >
                            {app.otp.moduleId.description}
                        </div>
                        <div className='engList-scrollable-darkgray' style={{ width: '100%', maxHeight: '90%', marginTop: '20px', padding: '0px 10px', overflowY: 'auto' }} >
                            {
                                Object.keys(app.engList).length > 0 && app.engList.map((item, i) => <Button key={item._id} style={{ padding: '10px', color: "Black", width: '70%', margin: '10px 0px', cursor: 'pointer', boxShadow: "0px 0px 6px #B0B0B0B3", background: "#FAFAFA 0% 0% no-repeat padding-box", opacity: "1" }}
                                    onClick={() => {
                                        if (app.enableEngagement.includes(item.engagement)) { handleClick(item) }
                                        else { alert("Engagement not active yet") }
                                    }}
                                    className={app.enableEngagement.includes(item.engagement) ? 'open-eng-list' : ''}
                                // disabled={!app.enableEngagement.includes(item.engagement)}
                                >
                                    {item.engagementName}
                                </Button>)
                            }
                        </div>
                    </div> : <Engagement data={eng} moduleId={app.otp.moduleId.id} />
                    }
                </div>
            </Grid>
        </Grid>
    )
}