import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";

import "./auth.css";
import { useMediaQuery } from "@material-ui/core";
import { loginWithToken } from "./redux_actions";
import { useDispatch } from "react-redux";

let Auth = () => {
  const isDesktop = useMediaQuery("(min-width:980px)");
  
  // Auto Login
  const location = useLocation()
  const search = location.state.from.search;
  const searchParams = new URLSearchParams(search)
  const studentId = searchParams.get('studentId');
  const token = searchParams.get("token")
  const dispatch = useDispatch();
  useEffect(() => {    
    if (token) {
      loginWithToken(dispatch, token)
    }
  }, [token])

  return (
    <div
      className={isDesktop ? "desktop auth" : "mobile"}
      style={{ width: "100%" }}
    >
      <Switch>
        <Route path="/auth/login" component={Login} />
        <Route path="/auth/register" component={Register} />
        <Route path="/auth/reset-password" component={ForgotPassword} />
        <Route path="/auth/" component={Login} />
      </Switch>
    </div>
  );
};

export default Auth;
