// import "./style.css";
import React, { useEffect, useState } from "react";
import units from "../../explorations";
import Grid from "@material-ui/core/Grid";
import Header from "../Header";
import ExternalResource from "../ExternalLink";
import { useMediaQuery } from "@material-ui/core";
import { LineWeight } from "@material-ui/icons";
var sanitizeHtml = require("sanitize-html");

let Links = (props) => {
  const [show, setShow] = useState(false);
  let { unit } = props;
  const { token, uid, logEvent, resourceId, moduleId } = props;

  console.log("props", unit);

  const isDesktop = useMediaQuery("(min-width:980px)");
  useEffect(() => {
    logEvent(token, uid, "RESOURCE_ACCESSED", { resourceId, moduleId });
  }, []);
  const styleObj = {
    fontSize: "30p",
  };
  return (
    <Grid
      container
      direction="column"
      style={{
        backgroundColor: "#f1f3f4",
        paddingBottom: "10px",
      }}
    >
      {unit?.pages.map((page, index) => {
        return (
          <div
            style={{
              backgroundColor: "white",
              marginTop: index == 0 ? 0 : "3%",
            }}
          >
            {isDesktop ? <Header /> : ""}
            {/* {unit.src1?} */}
            <Grid
              container
              spacing={1}
              className="external-links"
              style={{ padding: "10px 32px", marginTop: 5 }}
            >
              <Grid
                item
                xs={12}
                sm={4}
                style={{
                  // font: "bold 28px / 1.3 ",
                  color: "#5b9ad5",
                }}
              >
                <div
                  style={{
                    marginTop: "10%",
                    fontFamily: "Metropolis",
                    fontWeight: "bold",
                    fontSize:"30px",
                    lineHeight:'1.3'
                  }}
                >
                  {page?.description}
                </div>
                {/* <div><img src={unit.icon} width="100%" /></div> */}
              </Grid>
              <Grid item xs={12} sm={8}>
                <Grid container>
                  <Grid item xs={12} sm={8} style={{}}>
                    <div>
                      {
                        <div
                          style={
                            {
                              // color: "#1D4352",
                              // fontFamily: "Metropolis",
                              // fontWeight: "medium",
                            }
                          }
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(page?.info),
                          }}
                        />
                      }
                      {page?.urls?.length > 0 && (
                        <div className="highlighted-patch">
                          <div
                            className="f15_b"
                            style={{ padding: "10px 15px 0px 15px" }}
                          >
                            Links
                          </div>
                          {page?.urls?.map((url) => (
                            <ExternalResource url={url} />
                          ))}
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!isDesktop ? (
              <div
                style={{
                  display: "flex",
                  marginBottom: "2%",
                  padding: "32px 32px",
                }}
              >
                <img
                  height={36}
                  alt="site-logo"
                  src="https://s3.ap-south-1.amazonaws.com/illumine-logos/illuminelogo.svg"
                />
                <div style={{ fontSize: "12px", marginTop: "2.5%" }}>
                  Illumine Digital
                  <br />
                  Resource Center
                </div>
              </div>
            ) : (
              " "
            )}
          </div>
        );
      })}
    </Grid>
  );
};

export default Links;
