import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import Glide from '@glidejs/glide';
import './book.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const leftArrow = <svg
  xmlns="http://www.w3.org/2000/svg"
  fill="#FFFFFF"
  width="36"
  height="36"
  viewBox="0 0 18 18"><path d="M11.56 5.56L10.5 4.5 6 9l4.5 4.5 1.06-1.06L8.12 9z" /></svg>
const rightArrow = <svg
  xmlns="http://www.w3.org/2000/svg"
  fill="#FFFFFF"
  width="36"
  height="36"
  viewBox="0 0 18 18"><path d="M7.5 4.5L6.44 5.56 9.88 9l-3.44 3.44L7.5 13.5 12 9z" /></svg>

function BookMultiPage(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [padding, setPadding] = useState(4);
  const { token, uid, logEvent, resourceId, moduleId } = props

  let duringLoad = props.duringLoad;
  let docRatio = props.docRatio || 0.63333; //default is potrait

  var w = window.innerWidth;
  var h = window.innerHeight;
  var deviceRatio = w / h;
  let glide;
  useEffect(() => {
    logEvent(token, uid, "RESOURCE_ACCESSED", { resourceId, moduleId })
  }, [])

  useEffect(() => {
    if (w / h >= docRatio) {
      // This need to be fixed to the height
      setWidth(h * docRatio);
      setHeight(h);
    } else {
      //This need to be fixed to width
      setWidth(w);
      setHeight(w / docRatio);
    }
  }, []);

  useEffect(() => {
    if (numPages > 0) {
      glide = new Glide('.glide', { perView: 1 })
        .mount()
        .on('run', (ev) => {
          document
            .getElementById('current-page')
            .innerHTML = glide.index + 1;
        });
    }
  }, [numPages]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    logEvent(token, uid, "PROGRESS", { resourceId, moduleId, page: 1 })
  }

  return (
    <div className='_book-cntr'>
      <Document
        loading={duringLoad}
        className='document-cntr'
        file={props.src}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
      >
        <div className="glide">
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              {numPages !== null ? populatePages(numPages, width, height, padding, logEvent, moduleId, resourceId, uid, token) : null}
            </ul>
          </div>
          <div
            className="glide__arrows"
            data-glide-el="controls"
            style={{
              width
            }}>
            <button className="glide__arrow glide__arrow--left" data-glide-dir={"<"} onClick={() => {
              // console.log(glide.index + 1)
              logEvent(token, uid, "PROGRESS", { resourceId, moduleId, page: glide.index + 1 })
            }
            }>{leftArrow}</button>
            <button className="glide__arrow glide__arrow--right" data-glide-dir={">"} onClick={() => {
              // console.log(glide.index + 1)
              logEvent(token, uid, "PROGRESS", { resourceId, moduleId, page: glide.index + 1 })
            }
            }>{rightArrow}</button>
          </div>
        </div>
        <div className="page-numbers">
          <span id='current-page'>1</span>/<span id='number-of-pages'>{numPages}</span>
        </div>
      </Document>
    </div>
  );
}

function populatePages(numPages, width, height, padding) {
  let canvasWidth = width - 2 * padding;
  let array_pages = [];
  for (let i = 1; i <= numPages; i++) {
    array_pages.push(
      <li
        key={'pg-' + i}
        className='page-cntr glide__slide'
        style={{
          width,
          height
        }}><Page
          pageNumber={i}
          width={canvasWidth}
          renderTextLayer={false}
          onRenderSuccess={() => {

          }} /></li>
    )
  };
  return array_pages;
}

export default BookMultiPage;