import React, { useEffect } from 'react';

let DownloadablePDF = ({ src, token, uid, logEvent, resourceId, moduleId }) => {
  useEffect(() => {
    logEvent(token, uid, "RESOURCE_ACCESSED", { resourceId, moduleId })
  }, [])
  return <div className='downloadabe-pdf-cntr' style={{ height: '100%' }}>
    <iframe src={src} width='100%' height='100%' />
    {/* <object data={src} width='100%' height='100%'></object> */}
  </div>
}

export default DownloadablePDF;