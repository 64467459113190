import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import Resources from "./1.Resources.js";
import Resource from "./2.Resource.js";
import "./redux_actions";

export default () => {
  // let app = useSelector((state) => state.app);
  // let auth = useSelector((state) => state.auth);
  const [redirect, setRedirect] = useState(undefined);

  let location = useLocation();

  useEffect(() => {
    // console.log(location)
    if (location?.state?.from) {
      setRedirect(location?.state?.from.pathname)
    }
  }, [location])

  if (redirect) {
    return <Redirect to={redirect} />
  }
  // else
  return (
    <Switch>
      <Route exact path="/app/:moduleId/resource/:resourceId" component={Resource} />
      <Route exact path="/app" component={Resources} />
    </Switch>
  );
};
