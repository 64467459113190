import React, { useState, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import success from "./Success.svg";
import { updateUserByIdChange } from "../../containers/auth/redux_actions";
import { Link } from 'react-router-dom'
import './style.css'
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },

});

const StyledDialog = withStyles({
    // '@global': {
    //   '.MuiPaper-rounded': {
    //     borderRadius: '15px',
    //     padding: '30px'
    //   },
    //   '.MuiOutlinedInput-root': {
    //     borderRadius: '15px',
    //   },
    //   '.MuiDialog-paper': {
    //     width: '600px'
    //   },
    //   '.MuiTypography-body1': {
    //     lineHeight: '2'
    //   }
    // },
    paper: {
        borderRadius: '15px',
        padding: '20px',
    }
})(Dialog);



const DialogTitle = withStyles(styles)((props) => {

    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h5">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        height: '70%'
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),

    },
}))(MuiDialogActions);


const customLink = {
    color: 'black',
    textDecoration: 'none',
}


function AddCollegeNamePopup({ app, auth }) {



    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
        updateUserByIdChange();
    };
    return (
        <StyledDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>

            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                <Typography style={{ fontSize: '1.5rem' }}>Thanks for sharing the information</Typography>
            </DialogTitle>
            <DialogContent style={{ justifyContent: 'center', textAlign: 'center' }}>
                <div className={"loginform-cntr"}>
                    <div className="successMsg text-left flexBox flex-center">
                        <img src={success} alt="Illumine" /> <label className="text-success ml-3">Success</label>
                    </div>
                    <Typography gutterBottom>Your is profile updated successfully</Typography>
                    
                    {/* <Button style={customButton_2} onClick={handleClose} variant="outlined" >
                                    ok
                                </Button> */}
                </div>
            </DialogContent>
        </StyledDialog>
    );
}


export default AddCollegeNamePopup;